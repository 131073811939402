import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setLoggedIn } from '../redux/userSlice'
import { LogOut } from '../API/CallApi'

export default function Navigation({children}) {
    const is_logged_in = useSelector(state => state.user.loggedIn)
    const dispatch = useDispatch()

    const getLoggedInLinks = (logged_in) => {
        if (logged_in) {
            return (
                <Nav>
                    <Nav.Link as={Link} to="/recept/voegtoe">voeg recept toe</Nav.Link>
                    <Nav.Link as={Link} to="/categorie/voegtoe">voeg categorie toe</Nav.Link>
                    <Nav.Link onClick={e => {e.preventDefault(); LogOut(); dispatch(setLoggedIn(false))}} >log uit</Nav.Link>
                </Nav>
            )
        }

        return (
            <Nav>
               
                <Nav.Link as={Link} to="/login">login</Nav.Link>
            </Nav>
        )
    }

    return(
        <Navbar  expand="lg" style={{backgroundColor: 'var(--primary)', padding:'10px 20px'}} variant="light">
            <Navbar.Brand href="/">{"{ lekkar lekkar }"}</Navbar.Brand>
            <Nav.Link as={Link} to="/bakken">bakken</Nav.Link>
            <Nav.Link as={Link} to="/koken">koken</Nav.Link>
            {getLoggedInLinks(is_logged_in)}    
        </Navbar>
    )
  }
