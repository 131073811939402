import React, {useState} from "react";
import { Badge, Form, InputGroup } from "react-bootstrap";
import calculateWaterTemperature from "../misc/CalculateWaterTemp";

import "./BreadCalculator.css"

const Section = function({children}) {
    return (
        <div style={{marginBottom: "2rem", padding: '16px', border: '4px solid var(--primary)'}}>{children}</div>
    )
}

const WaterTempCalculator = function() {
    const [desired_temp, setDesiredTemp] = useState(27)
    const [room_temp, setRoomTemp] = useState(21)

    const [flour_mass, setFlourMass] = useState(500)
    const [water_mass, setWaterMass] = useState(400)
    const [starter_mass, setStarterMass] = useState(80)
    const [flour_temp, setFlourTemp] = useState(21)
    const [starter_temp, setStarterTemp] = useState(21)

    const cssInput = {marginBottom: '12px'}

    const validateAndChange = (input, updateFunc) => {
        const regex = /^[0-9\b]+$/;
        if (input === "" || regex.test(input)) 
            updateFunc(input)
    }
    
    return (
        
        <div className="page">
        <div>
            <div>
                <h1 style={{fontSize: '4rem', textAlign: 'center'}}><Badge style={{color:'black', background: 'var(--primary)', margin:'0'}}>{Math.round(calculateWaterTemperature(
                    parseInt(flour_mass) || 0, // mass of flour
                    parseInt(water_mass) || 0, // mass of water
                    parseInt(starter_mass) || 0, // mass of starter
                    parseInt(room_temp) || 0, // temp of environment (cels)
                    parseInt(flour_temp) || 0, // temp of flour (cels)
                    parseInt(starter_temp) || 0, // temp of starter (cels)
                    parseInt(desired_temp) || 0, // target temperature (cels)
                    true // bool for is using a mixer nor not
                )) } &deg;C</Badge></h1>
            </div>
            
                <Form>
                    <Section>
                    <div className="flex-row">
                        <div className="flex-column">
                            <h5>omgevingstemp.</h5>
                            <InputGroup style={cssInput} size="lg">
                                <Form.Control type="number" inputMode="decimal" onChange={e => validateAndChange(e.target.value, setRoomTemp)} value={room_temp} ></Form.Control>
                                <InputGroup.Text>&deg;C</InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="flex-column">
                            <h5>gewenste temp.</h5>
                            <InputGroup style={cssInput} size="lg">
                                <Form.Control type="number" inputMode="decimal" onChange={e => validateAndChange(e.target.value, setDesiredTemp)} value={desired_temp} ></Form.Control>
                                <InputGroup.Text>&deg;C</InputGroup.Text>
                            </InputGroup>
                        </div>
                    </div>
                    </Section>

                    <Section>
                        <h5>starter/levain</h5>
                        <div className="flex-row">
                            <div className="flex-column">
                                <InputGroup style={cssInput} size="lg">
                                    <Form.Control type="number" inputMode="decimal" onChange={e => setStarterMass(e.target.value)} value={starter_mass} ></Form.Control>
                                    <InputGroup.Text>{"gram"}</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="flex-column">
                            <InputGroup style={cssInput} size="lg">
                                <Form.Control type="number" inputMode="decimal" onChange={e => setStarterTemp(e.target.value)} value={starter_temp} ></Form.Control>
                                <InputGroup.Text>&deg;C</InputGroup.Text>
                            </InputGroup>
                            </div>
                        </div>
                    </Section>

                   
                   
                    <Section>
                        <h5>bloem</h5>
                        <div className="flex-row">
                            <div className="flex-column">
                                <InputGroup style={cssInput} size="lg">
                                    <Form.Control type="number" inputMode="decimal" onChange={e => setFlourMass(e.target.value)} value={flour_mass} ></Form.Control>
                                    <InputGroup.Text>{"gram"}</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="flex-column">
                            <InputGroup style={cssInput} size="lg">
                                <Form.Control type="number" inputMode="decimal" onChange={e => setFlourTemp(e.target.value)} value={flour_temp} ></Form.Control>
                                <InputGroup.Text>&deg;C</InputGroup.Text>
                            </InputGroup>
                            </div>
                        </div>
                        
                    </Section>

                    <Section>
                        <h5>water</h5>
                            
                        <InputGroup style={cssInput} size="lg">
                            <Form.Control type="number" inputMode="decimal" onChange={e => setWaterMass(e.target.value)} value={water_mass} ></Form.Control>
                            <InputGroup.Text>{"gram"}</InputGroup.Text>
                        </InputGroup>
                        
                    </Section>

                    
                </Form>

            </div>
        </div>
    )


}

export default WaterTempCalculator