import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {FormControl} from 'react-bootstrap'
import {PostApi} from '../API/CallApi'
import { makeURLSafe } from '../misc/Cleanup'
import './Search.css'

function SearchBar({onUpdate, onInput, type = "", category_id = -1}) {
    const [items, setItems] = useState([])
    const [current_term, setTerm] = useState(null)

    
   
    useEffect( () => {
        onUpdate(items)
        onInput(current_term)

    }, [items, setItems, current_term, onInput, onUpdate])

    const apiCall = (target) => {
        if (category_id >= 0) {
            return PostApi('recipe/search_category', {term: target, category_id})
        }
        if (type !== "") {
            
            return PostApi('recipe/search_type', {term: target, type:type})
        }
        return PostApi('recipe/search', {term: target})
    }

    return (
        <FormControl
            placeholder="Zoek..."
            onChange={async (e) => {
                if (e.keyCode === 38) return
                if (e.keyCode === 40) return
                setTerm(e.target.value)
                const response = await apiCall(e.target.value)//await PostApi('recipe/search', {term: e.target.value})
                if (response.status === 200) {
                    const recipes = await response.json()
                    setItems(recipes)
                }
                else
                setItems([])
            }}
        />
    )
}

export default SearchBar

export function SearchPage({category_id, type}) {
    const [items, setItems] = useState([])
    const [current_term, setTerm] = useState("")
    const [active_item, setActiveItem] = useState(0)

    const history = useHistory()

    const createLink = (recipe) => `/recept/${recipe.id}/${makeURLSafe(recipe.title)}`

    useEffect( () => {
        const handleDown = event => {
            const { keyCode } = event
            // UP ARROW
            if (keyCode === 38) {
                event.preventDefault()
                setActiveItem(prev => prev === 0 ? prev : prev - 1)
            }
            // DOWN ARROW
            else if (keyCode === 40) {
                event.preventDefault()
                setActiveItem(prev => prev === items.length - 1 ? prev : prev + 1)
            }
            // ENTER
            else if (keyCode === 13) {
                const the_recipe = items[active_item]
                history.push(createLink(the_recipe))
            }
        }
    
        const handleUp = event => {
            //const { releasedKey } = event
        }
    
        window.addEventListener("keyup", handleUp)
        window.addEventListener("keydown", handleDown)
    
        return () => {
            window.removeEventListener("keyup", handleUp)
            window.removeEventListener("keydown", handleDown)
        }
    }
        , [setActiveItem, items, active_item, history]
    )
   
    const suggestionBox = items.length ? (<ul className="suggestions">
    {items.map( (item, i) => {
        const [first, ...rest] = item.title.toLowerCase().split(current_term.toLowerCase())
        const link = createLink(item)
        return (
            <li 
                onMouseEnter={() => setActiveItem(i)  }
                onMouseDown={ () => history.push(link) }
                style={ active_item === i ? {backgroundColor: 'var(--background-secondary)'} : {} }
            >
            <Link  to={link}>{first}<span style={{color: 'var(--primary)', textDecoration: 'underline'}}>{current_term}</span>{rest.join(current_term)}</Link>
            </li>
        )
    })}
</ul>)
: <></>

    return (

        <div style={{position: 'relative'}}>
            <SearchBar category_id={category_id} type={type} onInput={setTerm} onUpdate={(new_items) => {
                setItems(new_items)
                if (active_item > items.length - 1) {
                    setActiveItem(prev => 0)
                }
            }}/>
        
            {suggestionBox}
            
        </div>
    )
}