import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, InputGroup, FormLabel } from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import Block from './Block'
import Page from './Page'

import { GetApi, ImageUpload, PostApi } from '../API/CallApi'
import Admin from './Admin'

const EMPTY_INGREDIENT = {name: "", unit: "", qty: ""}
const EMPTY_INGREDIENT_LIST = {name: "", list: [EMPTY_INGREDIENT]}
const EMPTY_RECIPE = {
    title: "",
    description: [{header: "", body: ""}],
    type: 'bakken',
    category_id: 4,
    tips: '',
    default_number_of_people: 2,
    preparation_time: 30,
    ingredient_list: [EMPTY_INGREDIENT_LIST],
    picture_url: "",
    equipment: null
}

function  EquipmentList({data, handleChange}) {
    const [the_list, setList] = useState(data || [])

    useEffect(() => handleChange(the_list), [the_list, setList, handleChange])

    if (true) {
        return (
            <div>
                {the_list.map( (tool, index) => 
                <InputGroup  key={index}>
                    <Form.Control size="sm" style={{width:'60%'}} type="text"  onChange={ e => {
                        setList(prev => {
                            prev[index] = e.target.value
                            return  JSON.parse(JSON.stringify(prev))
                        })
                    }} value={tool} placeholder="Ding" ></Form.Control>
    
    
                    <Button variant="outline-danger" onClick={e => {
                        e.preventDefault()
                        setList(prev => {
                            return [...prev.slice(0, index), ...prev.slice(index + 1)]
                        })
                    }}>x</Button>
                </InputGroup>
                )}
                <Button size="sm" style={{width:'100%'}}  variant="outline-secondary"  onClick={e => {
                    e.preventDefault()
                    setList(prev => {
                        return [...prev, ""] 
                    })
                }}>Voeg benodigdheid toe</Button>
            </div>
        )
    }
    return <></>    
}

export function IngredientList({data, handleChange}) {
   
    const [full_list, setList] = useState(data)
    
    useEffect(() => handleChange(full_list), [full_list, setList, handleChange])
   

    return (
        <div>
            {full_list.map((current_list, list_index) => 
            <div key={list_index} style={{padding: '20px 0'}}>
                <Button style={{float: 'right'}} size="sm" onClick={e => {
                    e.preventDefault()
                    setList(prev => [...prev.slice(0, list_index), ...prev.slice( 1 + list_index) ])
                }} >X</Button>
    
                <Form.Control value={current_list.name} onChange={e => {
                    e.preventDefault()
                    setList(prev_list => {
                        prev_list[list_index].name = e.target.value
                        return [...prev_list]
                    })
                }} />
    
                {current_list.list.map( (ingredient, ingredient_index) => {
                return (
                    <InputGroup  key={ingredient_index}>
                        <Form.Control size="sm" style={{width:'60%'}} type="text"  onChange={ e => {
                            setList(prev => {
                                let {unit, qty} = prev[list_index].list[ingredient_index]
                                const new_ingredient = {name: e.target.value, unit, qty}
                                prev[list_index].list[ingredient_index] = new_ingredient
                                return  JSON.parse(JSON.stringify(prev))
                            })
                        }} value={ingredient.name} placeholder="Ingredient" ></Form.Control>
    
    
                        <Form.Control size="sm" onChange={ e => {
                            setList(prev => {
                                let {name, unit} = prev[list_index].list[ingredient_index]
                                const new_ingredient = {name, unit, qty: e.target.value}
                                prev[list_index].list[ingredient_index] = new_ingredient
                                return  JSON.parse(JSON.stringify(prev))
                            })
                            
                        }} value={ingredient.qty} placeholder="1"></Form.Control>
    
    
                        <Form.Control size="sm" onChange={ e => {
                            setList(prev => {
                                let {name, qty} = prev[list_index].list[ingredient_index]
                                const new_ingredient = {name, unit: e.target.value, qty}
                                prev[list_index].list[ingredient_index] = new_ingredient
                                return  JSON.parse(JSON.stringify(prev))
                            })
                            
                        }} value={ingredient.unit} placeholder="eenheid"></Form.Control>
    
                        <Button variant="outline-danger" onClick={e => {
                            e.preventDefault()
                            setList(prev => {
                                let {list} = prev[list_index]
                                prev[list_index].list = [...list.slice(0, ingredient_index), ...list.slice(ingredient_index + 1)]
                                return JSON.parse(JSON.stringify(prev))
                            })
                        }}>x</Button>
                    </InputGroup>
                    )
                })}
    
                <div style={{margin: '0 auto', marginBottom:'40px', width: '100%'}}>
                    <Button size="sm" style={{width:'100%'}}  variant="outline-secondary"  onClick={e => {
                        e.preventDefault()
                        setList(prev => {
                            prev[list_index].list = [...prev[list_index].list, EMPTY_INGREDIENT] 
                            return JSON.parse(JSON.stringify(prev))
                        })
                    }}>Voeg ingrediënt toe</Button>
                </div>
    
            </div>
            )}
             <Button onClick={e => {
                    e.preventDefault()
                    setList(prev => {
                        prev.push(EMPTY_INGREDIENT_LIST)
                        return JSON.parse(JSON.stringify(prev))
                    })
                }}>Voeg lijst toe</Button>
        </div>
    )
}

export function RecipeDescription({data, handleChange}) {
    const [paragraphs, setParagraphs] = useState(data)

    useEffect( () => handleChange(JSON.parse(JSON.stringify(paragraphs))), [paragraphs, handleChange])

    return (
        <div>
            {paragraphs.map( (paragraph, i) => {
                const line_count = paragraph.body.split('\n\n').length * 2
                return (
                    <div key={i} style={{padding: '20px 0'}}>
                        <Button style={{float: 'right'}} size="sm" onClick={e => {
                            e.preventDefault()
                            setParagraphs(prev => [...prev.slice(0, i), ...prev.slice( 1 + i) ])
                        }} >X</Button>
                        <Form.Control size="lg" value={paragraph.header} onChange={e => {
                            e.preventDefault()
                            setParagraphs(prev => {
                                prev[i].header = e.target.value
                                return JSON.parse(JSON.stringify(prev))
                            })
                        }} placeholder="Kop" />
                        <Form.Control as="textarea" value={paragraph.body} onChange={e => {
                            e.preventDefault()
                            setParagraphs(prev => {
                                prev[i].body = e.target.value
                                return JSON.parse(JSON.stringify(prev))
                            })

                        }} rows={line_count} />
                    </div>
                )
            }
            )}

            <Button onClick={e => {
                e.preventDefault()
                setParagraphs(prev => {
                    prev.push({header: "", body: ""})
                    return JSON.parse(JSON.stringify(prev))
                })
            }}>Voeg paragraaf toe</Button>
        </div>

    )
}

export default function EditRecipe({is_edit}) {
   
   

    const {id} = useParams()

    const [fetched, setFetched] = useState(false)
    const [data, setData] = useState(EMPTY_RECIPE)
    const [available_categories, setAvailableCategories] = useState([])

    const file_input_ref = useRef()

    
    useEffect(() => {
        const getData = async (id) => {
            try {
                const [recipe_data, new_categories] = await Promise.all ( [GetApi(`recipe/${id}`),  GetApi(`getcategories`)] )

                const new_data = recipe_data[0]
                setData( original => is_edit ? new_data : original )
                
                setAvailableCategories( new_categories )


                setFetched(true)
            }
            catch (err) {
                console.error(err)
            }
        }

        if (!fetched)
            getData(id) 

        

    }, [id, is_edit, fetched, setFetched, setData, setAvailableCategories])

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        const uri = is_edit ? `recipe/update/${id}` :  `recipe/upload`

        
        if (true) {
            try {
                
                if (file_input_ref.current?.files[0]) {
                    const form_data = new FormData()
                    form_data.append('uploaded_file', file_input_ref.current.files[0])
                    
                    const resp = await ImageUpload(form_data)
                    if (resp) {
                        const {filename} = await resp.json()
                        setData(prev => {prev.filename = filename; return JSON.parse(JSON.stringify(prev))})
                    }
                }

                const {
                    title,
                    description,
                    type,
                    category_id,
                    tips,
                    default_number_of_people,
                    preparation_time,
                    ingredient_list,
                    filename,
                    equipment,
                } = data

                const resp = await PostApi(uri, {
                    title,
                    description,
                    type,
                    category_id,
                    tips,
                    default_number_of_people,
                    preparation_time,
                    ingredient_list,
                    picture_url: filename ? filename : data.picture_url,
                    equipment
                })

                const {insertId} = await resp.json()

                window.location.href = `/#/recept/${insertId}`
            }
            catch(err) {
                console.error(err)
            }


        }
    }

   
    const updateIngredientList = (new_list) => setData(prev => { prev.ingredient_list = new_list; return prev})
    const updateDescription = (new_description) => setData(prev => {prev.description = new_description; return prev})
    const updateEquipmentList = (new_list) => setData( prev => { prev.equipment = new_list; console.log(prev); return prev} )  
    
    if (fetched) {
        const {
            title,
            ingredient_list,
            equipment,
            description,
            default_number_of_people,
            preparation_time,
            tips,
            category_id,
            filename,
            type,

        } = data

        
        const sorted_categories = available_categories.filter(category => category.type === type).sort( (a, b) => a.name.localeCompare( b.name ) )

        return (
            <Admin>
            <Page>
                <div >

                    <Block title="Titel">
                        <Form.Control size="lg" value={title} onChange={ e => {
                            e.preventDefault()

                            setData(prev => {
                                prev.title = e.target.value
                                return {...prev}
                            })
                        }} />
                    </Block>

                    <Block title="Type & categorie">
                        <InputGroup>
                            <FormLabel style={{padding: '10px'}}>
                                type: 
                            
                            <select value={type} style={{margin: '1rem'}} onChange={(e) => { 
                                e.preventDefault()
                                setData(prev => {
                                    prev.type = e.target.value
                                    return {...prev}
                                })
                            }}>
                                <option value='bakken'>bakken</option>
                                <option value='koken'>koken</option>
                            </select>

                            </FormLabel>

                            <FormLabel style={{padding: '10px'}}>
                                categorie: 
                            
                            <select value={category_id} style={{margin: '1rem'}} onChange={(e) => { 
                                e.preventDefault()
                                setData(prev => {
                                    prev.category_id = e.target.value
                                    return JSON.parse( JSON.stringify(prev) )
                                })
                            }}>
                                
                                {sorted_categories.map(({name, id}) => <option key={id} value={id}>{name}</option>)}
                            </select>

                            </FormLabel>
                        </InputGroup>
                    </Block>

                    <Block title="Ingrediënten">
                        <IngredientList data={ingredient_list} handleChange={updateIngredientList} />
                    </Block>

                    <Block title="Benodigdheden">
                        <EquipmentList data={equipment} handleChange={updateEquipmentList} />
                    </Block>
                    
                    <Block title="Beschrijving">
                        <RecipeDescription data={description} handleChange={updateDescription} />
                    </Block>

                    <Block title="Aantal personen">
                    <Form.Control size="lg" value={default_number_of_people} onChange={e => {
                        e.preventDefault()
                        setData(prev => {
                            prev.default_number_of_people = e.target.value
                            return {...prev}
                        })
                    }} placeholder="Aantal personen" />
                    </Block>

                    <Block title="Bereidingstijd">
                    <Form.Control size="lg" value={preparation_time} onChange={e => {
                        e.preventDefault()
                        setData(prev => {
                            prev.preparation_time = e.target.value
                            return {...prev}
                        })
                    }} placeholder="Bereidingstijd" />
                    </Block>

                    

                    <Block title="tips">
                    <Form.Control as="textarea" rows={tips.split('\n\n').length * 2} value={tips} onChange={e => {
                        e.preventDefault()
                        setData(prev => {
                            prev.tips = e.target.value
                            return {...prev}
                        })
                    }} placeholder="Tips" />
                    </Block>


                    <Block title="Afbeelding">
                        <input ref={file_input_ref} className="d-none" type="file" name="uploadeded_file" onChange={e => {
                            e.preventDefault()
                            if (file_input_ref.current.files[0] ) {
                                const {name} = file_input_ref.current.files[0]
                                setData(prev => {
                                    prev.filename = name
                                    return {...prev}
                                })
                            }
                        }} />
                        <Button style={{margin: '1rem'}} variant="outline-primary" onClick={ e => {file_input_ref.current?.click()} }>Kies afbeelding</Button>
                        <p>{filename ? filename : ""}</p>
                    </Block>

                    <Block>
                        <Button disabled={false}  style={{width: '100%'}} size="lg" onClick={handleSubmit}>Opslaan</Button>
                    </Block>

                    <Block>
                        <Button onClick={async e => {
                            e.preventDefault()
                            const confirmed = window.confirm('Weet je zeker dat je dit recept wil verwijderen?')
                            if (confirmed) {
                                const resp = await PostApi(`recipe/remove/${id}`)
                                if (resp.status === 200) {
                                    window.location.href = "/"
                                }
                                else {
                                    alert('Kon recept niet verwijderen...')
                                }
                            }
                        }} style={{width: '100%'}} variant="danger">Verwijder</Button>
                    </Block>
                </div>

            </Page>
            </Admin>
        )
    }
    

    return <></>
}