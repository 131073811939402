import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useEffect, useState } from 'react';

import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom'


import Main from './components/Main'
import Navigation from './components/Navigation';

import ViewType from './components/ViewType';

import ViewRecipe from './components/ViewRecipe'
import EditRecipe from './components/EditRecipe';

import ViewCategory from './components/ViewCategory';
import AddCategory from './components/AddCategory'
import { Login } from './components/Admin';

import WaterTempCalculator from './components/BreadCalculator';

import { useDispatch } from 'react-redux';
import { setLoggedIn } from './redux/userSlice';
import { Spinner } from 'react-bootstrap';
import { CallApi } from './API/CallApi'


function App() {
  
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)


  useEffect(() => {
    const verifyLoggedIn = async () => {
      const resp = await CallApi( `loggedin`)
      dispatch(setLoggedIn(resp.status === 200))
      setFetched(true)
    }

    if (!fetched) {
      verifyLoggedIn()
    }
  }, [fetched, setFetched, dispatch])

  if (fetched) {

    return (  
        <Router>
          <Navigation />
          
          <div style={{margin: '0 auto', marginTop: '48px'}}>
            <Switch>
              <Route exact path="/">
                <Main />
              </Route>
  
              <Route path="/bakken" component={props => <ViewType type="bakken" />} />
              <Route path="/koken"  component={props => <ViewType type="koken" />} />
  
              <Route exact path="/login" component={props => <Login />} />
           
              <Route path="/recept/voegtoe" component={props => <EditRecipe  />} />
              <Route path="/recept/bewerk/:id" component={props => <EditRecipe  is_edit/>} />
  
              <Route path="/recept/:id">
                <ViewRecipe  />
              </Route>
  
              <Route path="/watertemperatuurcalculator" component={props => <WaterTempCalculator />} />
  
              <Route path="/categorie/:id/:type/:name" component={props => <ViewCategory />} />
              <Route path="/categorie/voegtoe">
                <AddCategory />
              </Route>
  
  
            </Switch>
          </div>
        </Router>
    );
  }

  return <div style={{width: '60px', margin: '0 auto', padding: '40px'}}>
    <Spinner animation="border" size="lg" style={{color: 'var(--primary)'}} />
  </div>
}

export default App;
