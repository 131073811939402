import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loggedIn: false,
        fetching: true
    },
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        }
    }
})

export const {setLoggedIn, setFetching} = userSlice.actions
export default userSlice.reducer