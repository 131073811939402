import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import './GridOverview.css'

import {GetApi} from '../API/CallApi'

function CategoryCard({name, id, is_orange}) {

    const [items, setItems] = useState([])
    const [fetched, setFetched] = useState(false)

    const classes = is_orange ? "flex-col orange" : "flex-col"

    const type = is_orange ? 'koken' : 'bakken'
    useEffect( () => {
        const getitems = async () => {
            const new_items = await GetApi(`recipes/withcategory/${id}`)
            setItems(new_items)
            setFetched(true)
        }

        if (!fetched)
            getitems()
    }, [items, setItems, fetched, setFetched, id] )

    if (fetched) {
        if (items.length) {
            return(
                <Link to={`/categorie/${id}/${type}/${name.toLowerCase()}`} className={classes} style={{width: '16rem'}} >
                    <h5>{name.toLowerCase()}</h5>
                    {items.length ? items.length : ""}
                </Link>
            )
        }

        return <></>
    }

    return <></>
}

export default function GridOverview({recipes, type}) {
    const [categories, setCategories] = useState([""])
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        const getCategories = async () => {
            try {
                const categories = await GetApi(`getcategories`)
                
                setCategories(categories)
                setFetched(true)
            }
            catch (err) {
                console.error(err)
            }
        }

        if (!fetched) {
            getCategories()
        }

    }, [setCategories, setFetched, fetched, recipes])

    const filtered_categories = categories.filter(category => category.type === type)

    if (fetched) {
        return (
            <div>
                <h1>{type}</h1>
                <div className="flex-grid" >
                    {filtered_categories.map(category => {
                        return (
                            <CategoryCard key={category.id} is_orange={type === 'koken'} id={category.id} name={category.name} />
                        )
                    })}
                </div>


                
            </div>
        )
    }

    return <></>
}