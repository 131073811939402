import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { PostApi, LogOut } from '../API/CallApi'
import { Redirect, useHistory } from 'react-router-dom'
import Page from './Page'

import { useDispatch, useSelector } from 'react-redux'
import { setLoggedIn } from '../redux/userSlice'

export function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [redirect, setRedirect] = useState(false)

    const dispatch = useDispatch()
    const logged_in = useSelector(state => state.user.loggedIn)

    const routerHistory = useHistory();

    if (redirect) {
        return <Redirect to="/" />
    }

    if (logged_in) {
        return (
            <Page>
                <h1>Reeds ingelogd.</h1>
                <Button onClick={e => {dispatch(setLoggedIn(false)); LogOut() }}>uitloggen</Button>
            </Page>
        )
    }

    return(
        <Page>
        <h1>Login</h1>
        <Form onSubmit={async e => {
            e.preventDefault()
            const resp = await PostApi(`gettoken`, {
                email, password
            })
            if (resp.status === 200) {
                               
                dispatch(setLoggedIn(true))
                setPassword("")

                if (routerHistory.length > 2) {
                    routerHistory.goBack()
                }
                else {
                    setRedirect(true)
                }
            }

        }}>
            <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="email" />
            <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="wachtwoord" />
            <Button block size="lg" type="submit" >
                Login
            </Button>
        </Form>
        </Page>
    )
}

export default function Admin({children}) {
    const is_logged_in = useSelector(state => state.user.loggedIn)
    if (is_logged_in) {
        return <div>{children}</div>
    }
    return (
     <Login />    
    )
}