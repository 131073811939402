
import React, {useState, useEffect } from 'react'
import {
    Form, Button
} from 'react-bootstrap'
import { GetApi, PostApi } from '../API/CallApi'
import Admin from './Admin'
import Block from './Block'

import Page from './Page'



export default function AddCategory() {
    

    const types = [
        'bakken', 'koken'
    ]
    
    const [fetched, setFetched] = useState(false)

    const [name, setName] = useState("")
    const [type, setType] = useState(types[0])
    const [all_categories, setCategories] = useState([])


    const getCategories = async () => {
        const categories = await GetApi(`getcategories`)
        setCategories(categories)
    }
    

    useEffect(() => {
        if (!fetched) {
            getCategories()
        }
    }, [fetched, setFetched])
 
    const handleSubmit = async (e) => {
        e.preventDefault()

        const uri = `addcategory`
        try {
            await PostApi(uri, {
                name, type
            })

            getCategories()
            
        }
        catch(err) {
            console.error(err)
        }

        clearForm();
    }

    const clearForm = () => {
        setName("")
        setType(types[0])
    }

    const validate = () => name !== "" && type !== ""


    const ordered = all_categories.sort( (a, b) => a.type.localeCompare(b.type) )


    return(
        <Admin>

            <Page>
                <Form>
                    <Block title="Naam" >
                    <Form.Control size="lg" type="text" placeholder="Naam" value={name} onChange={(e) => {
                            const {value} = e.target
                            setName(value)
                        }}></Form.Control>
                    </Block>

                    <Block title="type">
                        <select style={{margin: '1rem'}} onChange={(e) => { 
                            e.preventDefault()
                            
                            setType(e.target.value) 
                        }}>
                            {types.map((type) => <option key={type} value={type}>{type}</option>)}
                        </select>
                    </Block>

                    <Block>
                        <Button disabled={!validate()}  style={{width: '100%'}} size="lg" onClick={handleSubmit}>Opslaan</Button>
                    </Block>

                    <ul>
                    {ordered.map(category => <li key={category.id}>{category.type} -- {category.name}</li>)}
                    </ul>
                </Form>
            </Page>
        </Admin>
    )
}