import React from 'react'

export default function Page({children, full_width}) {
    const styles = full_width ? {
        padding:'20px', marginTop:'120px',
    } : {
        padding:'20px', margin: '0 auto', marginTop:'120px',  maxWidth: '1024px'
    }
    return (
        <div style={styles}>
            {children}
        </div>
    )
}