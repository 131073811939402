import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import {Form, InputGroup, Button, Badge} from 'react-bootstrap'

import './ViewRecipe.css'
import Page from './Page'
import { GetApi } from '../API/CallApi'

import { useSelector } from 'react-redux'


function ViewRecipe() {
    const [new_num_people, setNumPeople] = useState(-1)
    const [recipe, setRecipe] = useState(null)
    const [category, setCategory] = useState(null)
    const [fetched, setFetched] = useState(false)

    const {id} = useParams()

    useEffect( () => {
        const getdata = async () => {
            try {
                const new_recipes = await GetApi(`recipe/${id}`)
                setRecipe(new_recipes[0])

                let {category_id} = new_recipes[0]
                const new_categories = await GetApi(`getcategory/${category_id}`)
                setCategory(new_categories[0])

                setNumPeople(new_recipes[0].default_number_of_people)

                setFetched(true)
            }
            catch(err) {
                console.error(err)
            }
        }

        if (!fetched) {
            getdata()
        }

    } , [recipe, setRecipe, id, fetched, setFetched, setCategory, setNumPeople])


    const is_logged_in = useSelector(state => state.user.loggedIn)
   

    if (fetched) {
        const {
            title,
            description,
            tips,
            default_number_of_people,
            preparation_time,
            date_creation,
            ingredient_list,
            picture_url,
            equipment

        } = recipe

        const created_date = new Date(date_creation)

        const createMainImage = () => {
            if (picture_url) {
                return (
                    <div className="fixed-aspectratio">
                        <img className="main-image" src={`/uploads/${picture_url}`} alt="afbeelding" />
                    </div>
                )
            }
            else {
                return (
                    <div className="fixed-aspectratio">
                        <h2 className="main-image" style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#444"}}>geen afbeelding</h2>
                    </div>
                )
            }

            
        }


        const createIngredientOverview = (data) => {
            if (data[0].list) {
                return (
                    <div style={{marginLeft:'5%', float:'left'}}>
                        <h2>ingredi&euml;nten</h2>   
                        <InputGroup size="sm" style={{margin:'20px 0'}}>
                            <InputGroup.Text id="person_label">Personen</InputGroup.Text>
                            <Form.Control aria-describedby="personen_label" size="sm" style={{width: '2em'}} value={new_num_people} onChange={e => setNumPeople(+(e.target.value))} />
                            <Button onClick={e => {
                                e.preventDefault()
                                setNumPeople(num => num - 1 < 1 ? 1 : num -1)
                            }} variant="outline-info">-</Button>
                             <Button onClick={e => {
                                e.preventDefault()
                                setNumPeople(num => num + 1)
                            }} variant="outline-info">+</Button>
                        </InputGroup> 
                        {
                        data.map( (the_list, ii) => {
                            const getQuantity = (qty, ratio) => {
                                if (!qty) return null

                                let new_qty = qty * ratio
                                if (new_qty % 1 > 0) {
                                    return new_qty.toFixed(2)
                                }
                                return new_qty.toFixed(0)
                            }
                            
                            return(
                                <div key={ii} style={{marginBottom: '4em'}}>
                                    <h5 style={{marginBottom: '5px', lineHeight: '1em'}}>{the_list.name}</h5>
                                    <ul style={{marginTop: '0'}} className="ingredientlist">
                                        {the_list.list.map( (ingredient, i) => {
                                            let {qty, unit, name} = ingredient
                                            const ratio =  new_num_people / (default_number_of_people > 0 ? default_number_of_people : 1)
                                            qty = getQuantity(qty, ratio)
                                            return (
                                                <li key={i}><span style={{color: '#999'}}>{qty} {unit}</span> {name.toLowerCase()}</li>
                                            )    
                                        })}
                                    </ul>   
                                </div>
                            )
                        })
                        }
                    </div>
                )
            }

            return (
                <div style={{marginBottom: '4em'}}>
                <ul className="ingredientlist">
                    {data.map( (ingredient, i) => <li key={i}><span style={{color: '#999'}}>{ingredient.qty} {ingredient.unit}</span> {ingredient.name.toLowerCase()}</li>)}
                </ul>   
                </div>
            )
        } 

        const createEquipmentOverview = () => equipment?.length ? <div>
            <h2>benodigdheden</h2>
            <ul>
                {equipment.map( tool => <li><Badge style={{color:'black', background: 'var(--secondary)', margin:'0 8px'}}>{tool.toLowerCase()}</Badge></li>)}
            </ul>
        </div>
        : <></>
       
        return (
            <Page full_width={true}>
                
                <h1 style={{textAlign: 'center', color:'#bbb'}}>
                    <Link to={`/categorie/${category.id}/${category.type}/${category.name}`}>{category.name}{": "}</Link>
                    <span style={{color: 'var(--primary)'}}>{title}</span>
                    {is_logged_in ?<Link style={{fontSize: '1.2rem'}} to={`/recept/bewerk/${id}`}>{" [bewerk]"}</Link> : <></> }
                </h1>
               
                
    
                <div style={{margin: '0 auto', width: '80%', marginBottom: '20px'}}>
                    {createMainImage()}

                    {createIngredientOverview(ingredient_list)}
                </div>

                
                <div style={{margin: '0 auto', maxWidth:'860px', clear:'both'}}>

                    <h5 style={{clear: 'both'}}>{default_number_of_people} pers. / {preparation_time} min.</h5>

                    {createEquipmentOverview()}

                    <hr />

                    <h2>bereidingswijze</h2>
                    {description.map( (paragraph, i ) => {
                        return(
                            <div key={i}>
                            {paragraph.header !== "" ? <h4>{paragraph.header}</h4> : <></>}
                            <ol className="steps">
                                {paragraph.body.split("\n\n").map( (par, i) => <li key={i}>{par}</li>)} 
                            </ol>
                            <hr />
                            </div>
                        )
                    })}
                                      
                    {!tips.length ?  <> </> : 
                    <div className="tipblock">
                        <div style={{width:'10%', float:'left'}}>
                            <p style={{ color:'var(--secondary)'}}>tips:</p>
                        </div>
                        <div style={{ float:'left', width:'90%'}}>
                            {tips.split('\n\n').map( (tip, i) => <p key={i}>{">"} {tip}</p>)}
                        </div>
                    </div>
                    }   
                    
                    <p><small>&copy; {created_date.getDate()}-{created_date.getMonth() + 1}-{created_date.getFullYear()}</small></p>
                </div>
            </Page>
                
        )
    }

    return (
        <>
        </>
    )
}


export default ViewRecipe