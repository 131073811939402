const accents = {
    "á": "a",
    "à": "a",
    "ã": "a",
    "â": "a",
    
    "é": "e",
    "è": "e",
    "ê": "e",
    
    "í": "i",
    "ì": "i",
    "î": "i",

    "õ": "o",
    "ó": "o",
    "ò": "o",
    "ô": "o",

    "ú": "u",
    "ù": "u",
    "û": "u"
}

const replaceAccents = (str) => {
    const new_str = str.split("").map(c => accents[c] ? accents[c] : c)
    return new_str.join("")
}

export const makeURLSafe = (string) => replaceAccents( string ).toLowerCase().split(" ").map(word=> word.replace(/[^0-9a-z]/gi, '')).join("-")