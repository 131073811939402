
import Page from "./Page"
import GridOverview from "./GridOverview"

import { SearchPage } from "./Search"

export default function ViewType({type}) {
    
    return (
        <Page>
            <SearchPage type={type}/>
            <hr />
            <GridOverview type={type} is_orange={type === 'bakken'}/>
        </Page>
    )
}