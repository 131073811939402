const API_URI = 'https://lekkarlekkar.nl/api'

export const LogOut = async () => {
    await PostApi('logout')
}

export const ImageUpload = async (data) => {
    try {
        const resp = await fetch(`${API_URI}/imageupload`, {
            method: 'post',
            body: data,
        })

        return resp
    }
    catch(err) {
        console.error(err)
    }

    return false
}

export const PostApi = async (endpoint, data) =>  {
    try {
        const uri = `${API_URI}/${endpoint}`
        const resp = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return resp
        

    }
    catch (err) {
        console.log('Error', err)
    }
}

export const GetApi = async (endpoint) => {
    try {
        const uri = `${API_URI}/${endpoint}`
        const resp = await fetch(uri)

        if (resp.status === 200) {
            const data = await resp.json()
            return data
        }
        else {
            throw new Error(resp.status)
        }

    }
    catch (err) {
        console.log('Error', err)
    }
}

export const CallApi = async (endpoint) => {
    const res = await fetch(`${API_URI}/${endpoint}`)
    return res
}