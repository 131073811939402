import React, { useEffect, useState } from 'react'
import Page from './Page'
import { Card } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import { GetApi } from '../API/CallApi'

import { makeURLSafe } from '../misc/Cleanup'

export default function ViewCategory() {
    const {id, name, type} = useParams()

    const [fetched, setFetched] = useState(false)
    const [recipes, setRecipes] = useState([])

    useEffect(() => {
        const getRecipes = async (id) => {
            const endpoint = `recipes/withcategory/${id}`
            const new_recipes = await GetApi(endpoint)

            setRecipes(new_recipes)
            setFetched(true)
        }

        if (!fetched) {
            getRecipes(id)
        }
    }, [id, fetched, setFetched])

    if (fetched) {

        return (
            <Page>
                <h3><Link to={`/${type}`} >{type}:</Link> {name}</h3>

                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {recipes.map(recipe => {
                        return (
                            <Card key={recipe.id} variant="top" style={{width:'16rem', margin: '10px', backgroundColor: 'transparent',  borderRadius: '0', border: 'none'}}>
                                <Link to={`/recept/${recipe.id}/${makeURLSafe(recipe.title)}`}>
                                    <Card.Body>
                                    {recipe.picture_url 
                                        ? 
                                        <Card.Img 
                                            style={{height: '10rem', objectFit: 'cover', border: '4px solid var(--secondary)', borderRadius:'0'}} src={`/uploads/${recipe.picture_url}`} 
                                        /> 
                                        : 
                                        <div 
                                            style={{height: '10rem', border: '4px solid var(--secondary)', borderRadius:'0',display: "flex", justifyContent: "center", alignItems: "center", color: "#444"}}
                                        >
                                            <p style={{color: "#444", margin: '0'}}>geen afbeelding</p>
                                        </div>}
                                    <Card.Title style={{margin: '8px 0'}}>{recipe.title}</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        )
                    })}

                    </div>
                
                
            </Page>
        )
    }

    return <></>
}