import React, {useState, useEffect} from 'react'
import { GetApi } from '../API/CallApi'
import GridOverview from './GridOverview'
import Page from './Page'
import {SearchPage} from './Search'


function Main() {
    const [recipes, setRecipes] = useState([])
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        const getdata = async () => {
            try {
                const new_recipes = await GetApi(`recipes`)
                setRecipes(new_recipes)
                setFetched(true)
            }
            catch(err) {
                console.error(err)
            }
            
        }

        if (!fetched) {
            getdata()
        }
    }, [recipes, fetched, setFetched])

    if (fetched) {
        return (
            <Page>
                <SearchPage />
                <hr />
                <GridOverview type="bakken" />
                <hr />
                <hr />
                <GridOverview type="koken" is_orange />

            </Page>
            
        )
    }

    return <></>

}

export default Main